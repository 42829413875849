import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { dataLayerUpdate } from "../api/dataLayer/gaDataLayer"
import Layout from "../components/Layout"
import {
  imageCls,
  textAreaWrapperCls,
} from "../components/AcfPageBlocks/CoveringImageAndText/CoveringImageAndText.module.scss"
import { TextBlock } from "../components/UI/Common"
import { LinkButton } from "../components/UI/Common"
import Card from "../components/AcfPageBlocks/BranchesVariant2/Card"
import { StaticImage } from "gatsby-plugin-image"
import ThreePostsRow from "../components/AcfPageBlocks/ThreePostsRow/ThreePostsRow"
import { BRANCH_DETAILS } from "../utils/storeLocationHelper"



const PageNotFound = () => {

const allData = graphql`
  {
    allWpPost(limit: 3) {
      edges {
        node {
          id
          categories {
            nodes {
              name
            }
          }
          link
          uri
          title
          date
          id
          postsLayouts {
            postObject {
              ... on WpPost_Postslayouts_PostObject_PostContent {
                blurb
                mainImage {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        width: 700
                        aspectRatio: 1.79
                        quality: 75
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
              ... on WpPost_Postslayouts_PostObject_PostHeader {
                postTitle
                description
                mainImage {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        width: 700
                        aspectRatio: 1.79
                        quality: 75
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`




  dataLayerUpdate("page_not_found")
  const allDataField = useStaticQuery(allData)
  const {allWpPost} = allDataField

  // Latest Posts
  const postData = allWpPost.edges
  const filteredPost = postData.map(postObject => postObject.node)


  // Page Banner
  const linkText = `Take me back to ${process.env.ORG_TRADING_NAME}`

  const config = {
    imagePosition: "left",
    textAlignment: {
      desktop: "left",
      mobile: "left",
      tablet: "left",
    },
    padding: {
      top: {
        topMobile: "pt-8",
        topTablet: "pt-md-10",
        topDesktop: "pt-lg-13",
      },
      bottom: {
        bottomMobile: "pb-8",
        bottomTablet: "pb-md-10",
        bottomDesktop: "pb-lg-13",
      },
    },
  }

  return (
    <Layout
      staticSeo={{ seoTitle: "404 error", seoDescription: "Page not found" }}
      location={{ pathname: "page-not-found" }}>
      <Row>
        <Col xs={12} lg={{ span: 6, order: "first" }}>
          <StaticImage
            className={imageCls}
            src="../images/404-module-img.png"
            alt="404"
          />
        </Col>
        <Col xs={12} lg={6}>
          <div className={`${textAreaWrapperCls} justify-content-center`}>
            <div className="d-flex flex-column  align-items-center align-items-lg-start py-5 ">
              <h2 className="text-center text-lg-left pb-4">
                {process.env.PAGE_NOT_FOUND_SECTION_HEADING}
              </h2>
              <TextBlock
                text={process.env.PAGE_NOT_FOUND_DESCRIPTION}
                mode="muted"
                className="pb-4 text-center text-lg-left"
              />
              <LinkButton className="text-center" link="/" title={linkText} />
            </div>
          </div>
        </Col>
      </Row>
      <div>
        <ThreePostsRow
          sectionHeading={"All about bikes"}
          defaultPosts={filteredPost}
          selectedPosts={null}
          config={config}
        />
      </div>
      <Container className="py-4">
        <>
          <h2 className="text-center text-lg-left pb-4">
            You are welcome to visit us
          </h2>
          <TextBlock
            mode="muted"
            text="Our Shepards Bush showroom and workshop are located in West London."
          />
          <Row>
            {BRANCH_DETAILS.map(branch => (
              <Col xs={12} md={6}>
                <Card branch={branch} />
              </Col>
            ))}
          </Row>
        </>
      </Container>
    </Layout>
  )
}

export default PageNotFound

