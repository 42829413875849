export const BRANCH_DETAILS=[
  {
    branchName:'Shepherds Bush Showroom',
    city:process.env.SHEPHERDS_BUSH_CITY,
    postcode:process.env.SHEPHERDS_BUSH_POST_CODE,
    phone:process.env.SHEPHERDS_BUSH_PHONE_NUMBER,
    street:process.env.SHEPHERDS_BUSH_STREET,
    latitude: process.env.SHEPHERDS_BUSH_LATITUDE,
    longitude: process.env.SHEPHERDS_BUSH_LONGITUDE,
    image: {
      sourceUrl: "https://centralebikes.co.uk/static/6a5ec0ec1be90ed9f70a3b37dd77fa9d/a55a9/book-a-test-ride-blog.avif"
    },
    openingHours: [
      {
        "dayOfTheWeek": "Monday",
        "hours": "08:30-18:00"
      },
      {
        "dayOfTheWeek": "Tuesday",
        "hours": "08:30-18:00"
      },
      {
        "dayOfTheWeek": "Wednesday",
        "hours": "08:30-18:00"
      },
      {
        "dayOfTheWeek": "Thursday",
        "hours": "08:30-18:00"
      },
      {
        "dayOfTheWeek": "Friday",
        "hours": "08:30-18:00"
      },
      {
        "dayOfTheWeek": "Saturday",
        "hours": "10:00 - 17:00"
      },
      {
        "dayOfTheWeek": "Sunday",
        "hours": "Closed"
      }
    ]
  },
]
