import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { Section, SectionHeading, PostCard } from "../../UI/Common"

const ThreePostsRow = ({
  defaultPosts,
  sectionHeading,
  selectedPosts,
  config,
}) => {
  const { padding } = config || {}
  let posts = []

  if (!selectedPosts?.length) {
    posts = defaultPosts
  } else {
    const defaultPostsIds = defaultPosts.reduce((previous, current) => {
      posts.push(current)
      return [...previous, current.id]
    }, [])

    selectedPosts.forEach(item => {
      if (!(item.post.id in defaultPostsIds)) {
        posts.pop()
        posts.splice(0, 0, item.post)
      }
    })
  }

  return (
    <Section className="bg-secondary" padding={padding}>
      <Container>
        <SectionHeading text={sectionHeading} />
        <Row>
          {posts &&
            posts.map(post => {
              const {
                title,
                // date,
                id,
                uri,
                postsLayouts,
              } = post || {}
              const image = postsLayouts?.postObject[0]?.mainImage
              return (
                <Col sm={12} lg={4} key={id}>
                  <div className="d-flex justify-content-center justify-content-lg-start pb-4 h-100">
                    <PostCard
                      link={uri}
                      image={getImage(
                        image?.localFile
                      )}
                      alt={image?.altText}
                      // date={date}
                      title={title}
                      // description={description}
                    />
                  </div>
                </Col>
              )
            })}
        </Row>
      </Container>
    </Section>
  )
}

export default ThreePostsRow
